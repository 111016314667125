<template>
    <div class="main" style="padding-bottom: 0;">
        <div class="container" v-show="showPage">
            <form class="form-inline float-right" @submit.prevent="getLogs()">
                <input
                    class="form-control mr-sm-2"
                    v-model="searchLogsVal"
                    type="text"
                    :placeholder="activeLanguage.apiLogs.searchText"
                >
                <button class="btn nopadding" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
            </form>
            <h2>{{ activeLanguage.apiLogs.title }}</h2>
            <ApiLogsTable
                :activeLanguage="activeLanguage.apiLogs"
                :logs="logs"
                :all="false"
            />
            <div class="title">
                <h2><a href="https://developers.billink.nl" class="documentation">{{ activeLanguage.apiLogs.documentation }}</a></h2>
            </div>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"
	import vkbeautify from 'vkbeautify'

    import ApiLogsTable from '../../components/apiView/ApiDocView/ApiLogsTable'

	export default {
		name: "api-documentation",
        data() {
			return {
				searchLogsVal: '',
                logs: [],
				showPage: false
            }
        },
        mounted() {
			this.getLogs()
        },
        methods: {
			getLogs() {
				let params = {
					type: 'dash',
					filter: { content: this.searchLogsVal}
				}, loader = this.$loading.show({
					container: null,
					canCancel: true,
				})

				billinkAxios
                    .get('/app/api-log/list', { params })
                    .then(({data}) => {
	                    this.logs = data.log_list.map((log) => {
		                    log.request = vkbeautify.xml(log.request.trim())
		                    log.response = vkbeautify.xml(log.response.trim())

                            return log
                        })
                    })
                    .catch(err => console.error(err))
                    .then(() => {
                    	this.showPage = true
	                    loader.hide()
                    })
            }
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getApiDocLanguage'
			}),
		},
        components: {
	        ApiLogsTable
        }
	}
</script>

<style scoped lang="scss">
    .apiDoc-menu-wr{
        position: sticky;
        top: 70px;
    }
    .card-body-scroll-link + .collapse{
        display: none;
    }
    .list-group-item.list-group-item-action.active .collapse.submenu {
        display: block !important;
        dd {
            a {
                padding-top: 0.675em !important;
                border-top: 1px solid #495057;
            }
            &:nth-of-type(1) a {
                border-top: none;
            }
        }
    }
    .topics .active{
        color: #495057;
    }
    .scrollTarget{
        margin-top: -50px;
        &>*{
            padding-top: 50px;
        }
    }
    .documentation{
        text-decoration: underline;
    }
</style>