<template>
    <b-modal v-model="show" size="lg" hide-footer :title="'API Log'" :class="`extendedCode`" header-class="card-header pause" :key="log.id">
        <table style="width: 100%;" class="table">
            <thead>
            <tr>
                <th>{{ activeLanguage.apiLogsTable.name }}</th>
                <th>{{ activeLanguage.apiLogsTable.ip }}</th>
                <th>{{ activeLanguage.apiLogsTable.responseTime }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{ log.api_name }}</td>
                <td>{{ log.ip }}</td>
                <td>{{ log.response_time }}</td>
            </tr>
            </tbody>
        </table>
        <div class="table-wr">
        <table class="reqTable" style="width: 100%">
            <thead>
            <tr>
                <th>{{ activeLanguage.apiLogsTable.req }}</th>
                <th>{{ activeLanguage.apiLogsTable.res }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="show">
                <td>
                    <pre v-highlight><code class="xml" style="height: 100%; max-width: 445px;">{{ log.request }}</code></pre>
                </td>
                <td>
                    <pre v-highlight style="height: 619px"><code class="xml" style="height: 100%; max-width: 445px;">{{ log.response }}</code></pre>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </b-modal>
</template>

<script>
	export default {
		name: "ExtendedCode",
        props: ['log', 'activeLanguage'],
        data() {
			return {
				show: false
            }
        },
        methods: {
			toggle() {
				this.show = !this.show
            }
        }
	}
</script>

<style lang="scss" >
    @media (min-width: 992px) {
        .modal-lg {
             max-width: 930px !important;
         }
    }
 

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 930px !important;
    }
}
  

    .extendedCode {
        .table-wr {
            width: 100%;
            overflow-x: scroll;
        }
        .table thead tr:hover {
            background-color: inherit;
        }
        .reqTable {
            width: 100%;
            margin-top: 20px;
            th {
                font-size: 16px;
            }
            td {
                cursor: auto;
                background-color: #282828;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                &:nth-of-type(1) {
                    pre {
                        /*border-right: 10px solid #fff;*/
                        margin-bottom: 0;
                    }
                }
            }
            tr {
                &:hover {
                    background-color: inherit !important;
                }
            }
        }
        

        .modal-content {
            width: 900px !important;
        }
    }

</style>