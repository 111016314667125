<template>
    <table class="table">
        <thead>
        <tr>
            <th class="text-center" style="width: 110px;">
                {{ activeLanguage.apiLogsTable.clientName }}
            </th>
            <th class="text-center" style="width: 100px;">
                {{ activeLanguage.apiLogsTable.created }}
            </th>
            <th class="text-center" style="width: 120px;">
                {{ activeLanguage.apiLogsTable.name }}
            </th>
            <th class="text-center" style="width: 65px;">
                {{ activeLanguage.apiLogsTable.time }}
            </th>
            <th class="text-center" style="width: 65px;">
                {{ activeLanguage.apiLogsTable.responseCode }}
            </th>
            <th class="" style="width: 330px;">
                {{ activeLanguage.apiLogsTable.req }}
            </th>
            <th class="" style="width: 330px;">
                {{ activeLanguage.apiLogsTable.res }}
            </th>
        </tr>
        </thead>
        <tfoot class="tfoot-no-check" v-if="!all">
        <tr>
            <td colspan="7">
                <router-link to="/api/logs" class="btn btn-secondary mr-sm-2">
                    {{ activeLanguage.seeAllBtn }}
                </router-link>
            </td>
        </tr>
        </tfoot>
        <tbody>
        <tr v-for="log in logs" @click.prevent="showLogDetails(log)">
            <td class="cl text-center">
                <div style="width: 86px;overflow: hidden;text-overflow: ellipsis" v-b-tooltip.hover.right.title="log.client"> {{ log.client }}</div>
            </td>
            <td class="cl text-center">
                {{ log.created | sliceTheString(0, 10) }}
            </td>
            <td class="cl text-center">
                <div style="width: 120px;overflow: hidden;text-overflow: ellipsis" v-b-tooltip.hover.right.title="log.api_name"> {{ log.api_name }}</div>
            </td>
            <td class="cl text-center">
                {{ log.response_time }}
            </td>
            <td class="cl text-center">
                {{ log.response_code }}
            </td>
            <td class="cl" >
                <div class="ovh">{{ log.request }}</div>
            </td>
            <td class="cl">
                <div class="ovh">{{ log.response }}</div>
                <ExtendedCode
                    :activeLanguage="activeLanguage"
                    :log="log"
                    :ref="`codeExtended-${log.id}`"
                    :key="log.id"
                />
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import ExtendedCode from './ExtendedCode'

    export default {
		name: "ApiLogsTable",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			},
			logs: {
				type: Array,
				required: true
			},
			all: {
				type: Boolean,
				required: true
			},
		},
        methods: {
	        showLogDetails({id}) {
	        	this.$refs[`codeExtended-${id}`][0].toggle()
            }
        },
        components: {
	        ExtendedCode
        },
        watch: {
			logs: function (val) {
			}
        }
	}
</script>

<style scoped lang="scss">
    tr {
        cursor: pointer;
    }
    .ovh {
        height: 80px;
        overflow: hidden;
    }
    .table {
        table-layout: fixed;
    }
    .cut td{
        background-color: #ccf0ef;
    }
</style>